<template>
  <div>
    <div class="row">

      <div class="col-md">
        <Card :title="'Tambah Laporan Lab'">
          <template v-slot:body>
            <Form
              :form="form"
              purpose="add"
              route="lab-reports"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>


import Form from '@/component/lab-reports/Form.vue'
import Card from '@/view/content/Card.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: '',
        patient_id: '',
        doctor_id: '',
        lab_template_id: '',
        report: '',
        lab_category_id: '',
      },
    }
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab" },
      { title: "Tambah Laporan" },
    ])
  },

}
</script>
