<template>
  <div>

    <b-form @submit.stop.prevent="formOnSubmit">

      <div class="row">
        <div class="col-md">
          <!-- Input Date -->
          <b-form-group
            id="input-group-date"
            label="Tanggal:"
            label-for="input-date"
          >
            <b-form-datepicker
              id="input-date"
              v-model="form.date"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.date }}</small>
          </b-form-group>
        </div>
        <div class="col-md">
          <!-- Input Modal Trigger -->
          <b-form-group
            id="input-group-trigger-modal-patient"
            label="Pasien:"
            label-for="input-trigger-modal-patient"
          >
            <b-form-input
              id="input-trigger-modal-patient"
              v-model="display.patient_name"
              placeholder="Pilih Pasien"
              readonly
              @click="$bvModal.show('modal-patient')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.patient_id }}</small>
          </b-form-group>
        </div>
      </div>

      <!-- Input Lab Category -->
      <b-form-group
        id="input-group-category"
        label="Kategori:"
        label-for="input-category"
      >
        <treeselect
          v-model="form.lab_category_id"
          :multiple="false"
          :options="categories"
        />
        <small class="text-danger">{{ error.lab_category_id }}</small>
      </b-form-group>

      <!-- Input Template -->
      <b-form-group id="input-group-template">
        <label for="input-template">Template Laporan: <em class="text-muted">opsional</em></label>
        <treeselect
          v-model="form.lab_template_id"
          :multiple="false"
          :options="templates"
          @select="templateOnSelect"
        />
        <small class="text-danger">{{ error.lab_template_id }}</small>
      </b-form-group>

      <!-- Input Doctor -->
      <b-form-group
        v-if="currentUser.user_type_id != 1"
        id="input-group-doctor"
        label="Dokter:"
        label-for="input-doctor"
      >
        <treeselect
          v-model="form.doctor_id"
          :multiple="false"
          :options="doctors"
        />
        <small class="text-danger">{{ error.doctor_id }}</small>
      </b-form-group>

      <!-- Report input -->
      <b-form-group
        id="input-group-report"
        label="Laporan:"
        label-for="input-report"
      >
        <div class="document-editor">
          <ckeditor
            :editor="editor"
            @ready="onReady"
            v-model="form.report"
          ></ckeditor>
        </div>
        <small class="text-danger">{{ error.report }}</small>
      </b-form-group>

      <!-- Button -->
      <b-button
        type="submit"
        variant="primary"
      >Simpan</b-button>
      <b-button
        class="ml-2"
        variant="default"
        @click="$router.push('/lab-report/list')"
      >
        Batal
      </b-button>

    </b-form>

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />

  </div>
</template>

<script>

import TreeSelect from '@riophae/vue-treeselect'
import ModalPatient from '@/component/general/ModalPatient.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import { getUser } from '@/core/services/jwt.service.js'

export default {

  props: {
    route: String,
    form: Object,
    purpose: String,
  },

  components: {
    ModalPatient,
    TreeSelect,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: ''
      },
      // Select Option
      doctors: [],
      categories: [],
      patients: [],
      templates: [],
      // Editor
      editor: DecoupledEditor,
      // Error
      error: {
        date: '',
        patient_id: '',
        doctor_id: '',
        lab_template_id: '',
        report: '',
        lab_category_id: '',
      },
      // other
      doctorDisabled: this.purpose == 'edit' ? true : false,
      // current user
      currentUser: getUser()
    }
  },

  methods: {

    // CK Editor Initalization
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },

    setPatient(value) {
      this.form.patient_id = value.id
      this.display.patient_name = value.name
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = ''
        this.getDoctorsOption()
      }
      this.doctorDisabled = false
      this.$bvModal.hide('modal-patient')
    },

    async formOnSubmit() {
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/lab-report/list')
      }
    },

    async getDoctorsOption() {
      let route
      if (typeof this.form.patient_id !== 'undefined' && this.form.patient_id != '') {
        route = `doctors/get-by-patient/${this.form.patient_id}`
      } else {
        route = 'doctors'
      }
      let response = await module.setDoctorTreeSelect(route)
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Pilih Dokter', id: '', isDisabled: true })
      }
    },

    async getCategoriesOption() {
      let response = await module.setTreeSelect('lab-categories')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.categories = response.data
        this.categories.unshift({ label: 'Pilih Kategori Lab', id: '', isDisabled: true })
      }
    },

    async templateOnSelect(evt) {
      let selected = await module.get(`lab-templates/${evt.id}`)
      this.form.report = selected.template
    },

    async getTemplatesOption() {
      let response = await module.setTreeSelect('lab-templates')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.templates = response.data
        this.templates.unshift({ label: 'Pilih Template', id: '', isDisabled: true })
      }
    }

  },

  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.user_id
    }
    this.getTemplatesOption()
    this.getCategoriesOption()
  },

  watch: {
    'form.patient_id': function (newVal) {
      this.getDoctorsOption()
    },
    'form.patient_name': function (newVal, oldVal) {
      this.display.patient_name = newVal
    },
    'form.doctor_id': function (newVal) {
      if (typeof newVal === 'undefined') this.form.doctor_id = ''
    }
  }

}
</script>

<style scoped>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
